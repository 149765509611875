@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'AvertaStd-Regular';
    src: url('../public/fonts/AvertaStd-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-RegularItalic';
    src: url('../public/fonts/AvertaStd-RegularItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Bold';
    src: url('../public/fonts/AvertaStd-Bold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-BoldItalic';
    src: url('../public/fonts/AvertaStd-BoldItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Semibold';
    src: url('../public/fonts/AvertaStd-Semibold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Semibolditalic';
    src: url('../public/fonts/AvertaStd-SemiboldItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Take Note';
    src: url('../public/fonts/take-note.eot');
    src:
      url('../public/fonts/take-note.eot?#iefix') format('embedded-opentype'),
      url('../public/fonts/take-note.woff2') format('woff2'),
      url('../public/fonts/take-note.woff') format('woff'),
      url('../public/fonts/take-note.ttf') format('truetype'),
      url('../public/fonts/take-note.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  html {
    @apply font-sans text-base leading-5.5 tracking-tight text-gray-900;
  }

  h1,
  .h1 {
    @apply font-sans-bold text-[2rem] leading-9.5 tracking-tighter  md:text-[2.5rem] md:leading-[3rem] xl:text-[3.125rem] xl:leading-[3.75rem];
  }
  h2,
  .h2 {
    @apply font-sans-bold text-[1.75rem] leading-[2.063rem] tracking-tighter md:text-[2rem] md:leading-9.5 xl:text-[2.75rem] xl:leading-[3.25rem];
  }
  h3,
  .h3 {
    @apply font-sans-bold text-2xl tracking-tighter md:text-[1.75rem] md:leading-[2.063rem] xl:text-[2.25rem] xl:leading-[2.375rem] xl:tracking-tight;
  }
  h4,
  .h4 {
    @apply font-sans-bold text-xl leading-6 tracking-tight md:text-[1.375rem] md:leading-[1.625rem] xl:text-2xl xl:leading-7;
  }
  h5,
  .h5 {
    @apply font-sans-semibold text-lg leading-[1.313rem] tracking-tight;
  }
  h6,
  .h6 {
    @apply font-sans-semibold text-base leading-[1.188rem] tracking-tight;
  }
  .h7 {
    @apply font-sans-semibold text-sm leading-4;
  }
  .h8 {
    @apply font-sans-semibold text-[0.8125rem] leading-[0.938rem];
  }
  .h9 {
    @apply font-sans-semibold text-xs leading-3.5 tracking-tight;
  }

  .p1 {
    @apply font-sans text-xl leading-7 tracking-tight md:text-[1.375rem] md:leading-[1.875rem] xl:text-2xl xl:leading-[2.063rem];
  }
  .p2 {
    @apply font-sans text-lg leading-[1.563rem] tracking-tight;
  }
  .p3 {
    @apply font-sans text-lg leading-[1.563rem] tracking-tight;
  }
  .p4 {
    @apply font-sans text-base leading-[1.375rem];
  }
  .p5 {
    @apply font-sans text-sm leading-[1.188rem];
  }
  .p6 {
    @apply font-sans text-[0.813rem] leading-[1.125rem];
  }
  .p7 {
    @apply font-sans text-xs leading-4 xl:text-[0.813rem] xl:leading-4.5;
  }

  figure {
    @apply mb-4.5;
  }

  .post iframe {
    @apply w-full overflow-hidden;
  }

  /* Disable arrows on number inputs */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
