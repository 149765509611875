.address-autocomplete {
  position: relative !important;
  width: 100%;
}

.address-autocomplete__suggestions {
  padding: 0;
  background: white;
  border-radius: 3px;
  z-index: 1001;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  list-style: none;
  width: 100%;
}

.address-autocomplete__suggestions__item {
  font-size: 1rem;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.address-autocomplete__suggestions__item--active {
  background: #eef0f2;
  font-weight: bold;
}
